import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Empty } from "antd";

import { Auth } from "../interfaces/interfaces";
import Results from "./Results/Results";
import StatusDashboard from "./StatusDashboard/StatusDashboard";

const NotFound = () => (
  <>
    <Link to="/">Back</Link>
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  </>
);

interface ContentRouterProps {
  auth: Auth;
}

const ContentRouter: React.FC<ContentRouterProps> = ({ auth }) => (
  <Routes>
    <Route path="/" element={<StatusDashboard auth={auth}/>} />
    <Route path="category">
      <Route index element={<NotFound />} />
      <Route path=":category">
        <Route index element={<NotFound />} />
        <Route path="test">
          <Route index element={<NotFound />} />
          <Route path=":test" element={<Results auth={auth} />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default ContentRouter;

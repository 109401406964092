import * as actionTypes from "../main/actionTypes";
import {Auth, Creds, TestMapping} from "../../interfaces/interfaces";
import {getStatisticData, multiRemoveErrors} from "../../http";
import {message} from "antd";

export function setCategoriesList(categoriesList: string[]) {
    const action: Action = {
        type: actionTypes.SET_MENU_CATEGORIES,
        payload: { categories: categoriesList }
    }
    return action
}
export function setSubCategoriesList(subCat: TestMapping){
    const action: Action = {
        type: actionTypes.SET_SUB_CATEGORIES,
        payload: {
            subCategories: subCat
        }
    }
    return action
}

export const requestFullStats = (auth: Creds) => async (dispatch: DispatchType) => {
    try{
        const result = await getStatisticData(auth);
        dispatch({
            type: actionTypes.SET_STATS_DATA,
            payload: result
        })
    }catch (e){
        console.error("The attempt of deleting test is fallen!", e)
        message.error('The attempt of deleting test is fallen!');
    }
}

import {Button, Tooltip} from "antd";
import React, {useState} from "react";
import {TestData} from "../../../interfaces/interfaces";
import {CloseCircleFilled} from "@ant-design/icons";

interface ImageCardProps {
    test: TestData
}

const FailedResultCard: React.FC<ImageCardProps> = ({test}) => {
    const [imagesList, openImagesList] = useState(false)
    const [tracebackList, openTracebackList] = useState(false)
    const [logsList, openLogsList] = useState(false)
    let keys: any

    if(test.screens)
        keys = Object.keys(test.screens)
    else
        keys = []


    return <div>
        {keys.length !== 0 &&
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {!imagesList ? <a onClick={() => {
                    openImagesList(true)
                }}>Screens</a> :
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: 10}}>
                        <CloseCircleFilled onClick={() => {
                            openImagesList(false)
                        }}/>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    {
                        keys.map((key:any) => {
                            return <div style={{flexDirection: 'column'}}><Tooltip placement={"top"}
                                                                                   title={"Press to show full size"}>
                                <img style={{width: 200, borderStyle: "solid", borderWidth: 1, borderColor: '#c3ec95'}}
                                     src={'https://e2e.management.sceenic.co/category/WebSDKTest/screens/' + test.screens[key]}/>
                            </Tooltip>
                            </div>
                        })
                    }
                    </div>
                </div>}

            {!logsList ? <a onClick={() => {openLogsList(true)}}>Log links</a>:
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: 10}}>
                    <CloseCircleFilled onClick={() => {openLogsList(false)}}/>
                   <pre style={{width: '100%'}}>{JSON.stringify(test.logs, null, 2)}</pre>
                </div>}

        </div>
        }
        {test.traceback && <div style={{display: 'flex', flexDirection: 'column'}}>
        {!tracebackList ? <a onClick={() => {
                openTracebackList(true)
            }
                }>Traceback</a>:
            <div style={{
                backgroundColor: '#eaeaea',
                padding: 10,
                width: '100%',
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
            }}><CloseCircleFilled
                style={{
                    justifySelf: 'flex-end'
                }}
                onClick={() => {
                openTracebackList(false)
            }}/>
                <pre
                style={{
                    whiteSpace: 'normal',
                    width: '100%'
                }}
                > {JSON.stringify(test.traceback, null, 2)}</pre>
            </div>}
        </div>
        }

    </div>
}
export default FailedResultCard

import axios from 'axios';
import {Category, TestMapping, TestName} from "../interfaces/interfaces";

export const multiRemoveTests = async (listForDeleting: [], auth: any) => {
    const selectedCategory = window.location.pathname.split('/')[2];
    const promises = listForDeleting.map((item:any) =>
        axios.delete(`https://e2e.management.sceenic.co/category/${selectedCategory}/${item.unique}`, { auth }));
    const results =  await Promise.all(promises);

}
export const multiRemoveErrors = async(listForDeleting: [], auth: any) => {
    const selectedCategory = window.location.pathname.split('/')[2];
    const promises = listForDeleting.map((item: any) =>
     axios.delete(`https://e2e.management.sceenic.co/category/${selectedCategory}/history/${item.unique}/fail`, { auth }))

    const results = await Promise.all(promises)
}

export const getCategories = async (auth: any) => {
    const { data } = await axios.get<Category[]>(
        "https://e2e.management.sceenic.co/category",
        { auth }
    );
    return data;
}

export const getSubCategories = async (categories: [], auth: any) => {
    const promises = categories.map((category) =>
        axios.get<TestName>(
            `https://e2e.management.sceenic.co/category/${category}`,
            {
                auth,
            }
        )
    );
    const results =  await Promise.all(promises);
    const datas = results.map(({ data }, index) => [categories[index], data]);
    return Object.fromEntries(datas) as TestMapping;
}

export const login = async(username: string, password: string ) => {
   const result = await axios.get("https://e2e.management.sceenic.co/", {
        auth: {username, password},
    });
}

export const getStatisticData = async (auth: any) => {
    const result = await axios.get('https://e2e.management.sceenic.co/test/stat', {
        auth,
    })
    return result.data
}

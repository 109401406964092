import React, {Dispatch, useEffect} from 'react'
import { Card, Progress, Statistic, Popover, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {AppStore} from "../../store/store";
import {Auth} from "../../interfaces/interfaces";
import {requestFullStats} from "../../store/main/actionCreators";
import DashboardCardContent from "./DashboardCardContent";



interface DashboardProps {
    auth: Auth
}

const StatusDashboard: React.FC<DashboardProps> = ({auth}) => {

    const { categories = [], subCategories }  = useSelector<AppStore>(state => state.main) as MainState
    const { stats } = useSelector<AppStore>(state => state.main) as MainState
    const dispatch:Dispatch<any>  = useDispatch();

    useEffect(() => {
        dispatch(requestFullStats(auth.creds))
        const timer = setInterval(() => {
            dispatch(requestFullStats(auth.creds))
        }, 10000)
         return () => {
            clearInterval(timer)
         }
    }, [])


    return <>
        <div className='status-dashboard-container'>
            {categories.length &&
                categories.map((category:any) => {
                    const current = subCategories[category] as string[]
                    if(!current) return null
                    let styleForCard = '';
                    if(current.length === 1)
                        styleForCard = 'small-status-card'
                    if(current.length === 3)
                        styleForCard = 'middle-status-card'
                    if(current.length > 3)
                        styleForCard = 'large-status-card'


                    return  <Card title={category}
                                  headStyle={{
                                      paddingTop: 5,
                                      paddingBottom: 0,
                                      textAlign: 'center'
                                  }}
                                  bodyStyle={{
                                      paddingTop: 0,
                                      paddingBottom: 5,
                                  }}
                                  className={'status-card' + ' ' + styleForCard}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            {current.length && current.map((item: string) =>{
                                if(!stats) return null
                                  return <DashboardCardContent categoryItem={category} subCat={item} stats={stats} />
                            }
                            )}
                        </div>

                    </Card>
                })
            }
        </div>
    </>
}




export default StatusDashboard

import React, {Dispatch} from "react";
import './accordion.css'
import {Checkbox, Collapse, Tooltip} from "antd";
import { TestData } from "../../interfaces/interfaces";
import {useDispatch} from "react-redux";
import {addError, addLast, removeError, removeLast} from "../../store/webSdkTest/actionCreators";
import {CheckCircleFilled,  ClockCircleFilled, ExclamationCircleFilled} from "@ant-design/icons";
import AccordionDetail from "./Details/AccordionDetail";

const { Panel } = Collapse;

interface AccordionProps {
  data: TestData[];
  edit: boolean;
  type: string,
}

interface PreviewFrameProps {
  item: TestData;
}

const PreviewFrame: React.FC<PreviewFrameProps> = ({ item }) => {
  const username = React.useMemo(() => makeid(6), []);
  return (
    <div>
      <iframe
        src={`https://e2e-grid.web.app?username=${username}&sessionToken=${item.sessionToken}`}
        title="WTDEMOV2"
        width={900}
        height={540}
        allow="camera; microphone"
        loading="lazy"
      ></iframe>
    </div>
  );
};

function makeid(length: number): string {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const Accordion = React.memo<AccordionProps>(({ data, edit,type }): React.ReactElement => {

  const dispatch: Dispatch<any> = useDispatch();

  const addToList = (item: any) => {
    if(type === 'error'){
      dispatch(addError({unique: item.unique,}))
    }
    if(type === 'test'){
      dispatch(addLast({unique: item.unique,}))
    }
  }
  const removeFromList = (item: any) => {
    if(type ==='error'){
      dispatch(
          removeError({unique: item.unique,})
      )
    }
    if(type === 'test'){
      dispatch(removeLast({unique: item.unique,}))
    }

  }

  const renderIcon = (status: number) => {
    if(status === 1){
      return <Tooltip placement="top" title={'Passed'}>
        <CheckCircleFilled size={20} style={{color: '#4a8023', fontSize: 18, marginRight: 5}} />
      </Tooltip>
    }
    if(status === -1){
      return <Tooltip placement="top" title={'Failed'}>
        <ExclamationCircleFilled size={20} style={{color: '#d73333', fontSize: 18, marginRight: 5}} />
      </Tooltip>
    }
    if(status === 0){
      return <Tooltip placement="top" title={'In Process'}>
      <ClockCircleFilled size={20} style={{color: '#1e75db', fontSize: 18, marginRight: 5}} />
      </Tooltip>
    }
  }

  return (
    <Collapse accordion destroyInactivePanel>
      {data && data.map((item) => (
        <Panel
          key={item.unique}
          header={<div className={'accordion-header'}><div>
            {renderIcon(item.status)}
            {item.started}
          </div>
            {edit ?
              <Checkbox
                  style={{justifySelf: "flex-end"}}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                   !e.target.checked ?
                      removeFromList(item) : addToList(item)
                  }}/>:
              <div></div>}</div>}
         className={'panel-item'}
        >
          <AccordionDetail key={item.unique} test={item} />
          {item.status === 0 && <PreviewFrame item={item} />}
          {/*<pre>{JSON.stringify(item, null, 2)}</pre>*/}
        </Panel>
      ))}
    </Collapse>
  );
});

export default Accordion;

import React, {Dispatch, useState} from "react";
import axios from "axios";
import './results.css'
import { useParams, useNavigate } from "react-router-dom";
import {Spin, Card, PageHeader, Button, Tooltip, Popconfirm, Modal} from "antd";
import {DeleteOutlined, RedoOutlined} from '@ant-design/icons';
import { Auth, Creds, StatData, TestData } from "../../interfaces/interfaces";
import Stats from "../Stats";
import Accordion from "../Acordion/Accordion";
import Format from "string-format";
import lodash from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {clearDelErrorList, clearLastDList, dropMultiError, dropMultiLast} from "../../store/webSdkTest/actionCreators";
import {AppStore} from "../../store/store";

enum DataType {
  data = "data",
  dataFail = "dataFail",
  dataStats = "dataStats",
}

type UrlMapping = {
  [key in DataType]: string;
};

const requestUrls: UrlMapping = {
  data: "https://e2e.management.sceenic.co/category/{category}/history/{test}",
  dataFail:
    "https://e2e.management.sceenic.co/category/{category}/history/{test}/fail",
  dataStats:
    "https://e2e.management.sceenic.co/category/{category}/history/{test}/stat",
};

interface ResultsProps {
  auth: Auth;
}

interface PartialState {
  data: TestData[];
  dataFail: TestData[];
  dataStats: StatData[];
}

interface State extends PartialState {
  isLoading: boolean;
}

const defaultState: State = {
  data: [],
  dataFail: [],
  dataStats: [],
  isLoading: false,
};

type Action =
  | { type: "request" }
  | {
      type: "success";
      data: TestData[];
      dataFail: TestData[];
      dataStats: StatData[];
    }
  | { type: "failure"; error: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "request":
      return { ...state, isLoading: true };
    case "success":
      const { data, dataFail, dataStats } = action;
      return {
        data,
        dataFail,
        dataStats,
        isLoading: false,
      };
    case "failure":
      return defaultState;
    default:
      return state;
  }
};

const requests = async (
  auth: Creds,
  category: string,
  test: string,
  dispatch: React.Dispatch<Action>
) => {
  dispatch({ type: "request" });
  try {
    const promises = Object.values(requestUrls).map((url) =>
      axios.get<Array<any>>(Format(url, { category, test }), { auth })
    );
    // TODO: checks
    const responses = await Promise.all(promises);
    const pairs = lodash.zip(responses, Object.keys(requestUrls));
    const entries = pairs.map(([response, key]) => [key, response?.data]);
    const result: PartialState = Object.fromEntries(entries);
    dispatch({ type: "success", ...result });
  } catch {
    dispatch({ type: "failure", error: "???" });
  }
};

interface TitleProps {
  text: string;
  isLoading: boolean;
}

const Title: React.FC<TitleProps> = ({ text, isLoading }) => (
  <div style={{ height: 20 }}>
    {text}
    {isLoading && <Spin />}
  </div>
);

const Results: React.FC<ResultsProps> = ({ auth }) => {
  // TODO: detect that there is no category, test on the backend or in App
  const { category, test } = useParams();
  const navigate = useNavigate();
  const [editErrorList, setEditErrorList] = useState<boolean>(false);
  const [editLatest, setEditLatest] = useState<boolean>(false);
  const [confirmDError, setConfirmE] = useState<boolean>(false);
  const [confirmDLast, setConfirmL] = useState<boolean>(false);
  const [restartConfirm, setRestartConfirm] = useState<boolean>(false);

  const dispatch: Dispatch<any> = useDispatch()
  const { webSdk } = useSelector<AppStore>((state) => state) as AppStore
  const {dropLastList = [], dropTestErrorList = []} = webSdk
  const { creds } = auth;

  const [data, dsp] = React.useReducer(reducer, defaultState);

  const loadData = React.useCallback(async () => {
    if (!category) return;
    if (!test) return;
    requests(creds, category, test, dsp);
  }, [creds, category, test]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  React.useEffect(() => {
    const handle = setTimeout(() => loadData(), 3000);
    return () => {
      clearTimeout(handle);
    };
  }, [data, loadData]);

  const editMode = (type: string) => {
    if(type === 'error')
      setEditErrorList(!editErrorList);
    if(type === 'test')
      setEditLatest(!editLatest)
  }
  const cancelDel = (type: string) => {
    if(type ==='error'){
      dispatch(clearDelErrorList())
      setEditErrorList(false);
    }
    if(type === 'test'){
      dispatch(clearLastDList())
      setEditLatest(false)
    }
  }

  const dropTestErrorMulti = () => {
    dispatch(dropMultiError(auth.creds))
    setEditErrorList(false)
    setConfirmE(false)
  }

  const dropTestLastMulti = () => {
    dispatch(dropMultiLast(auth.creds))
    setEditLatest(false)
    setConfirmL(false)
  }

  const restartTests = () => {
    console.log('Test was restarted ')
    setRestartConfirm(false)
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        backIcon={false}
        title={test?.replaceAll('_', ' ')}
        //subTitle={category}
      />
      <Card title={
        <div className="table-header">
          <Title text="Stats" isLoading={data.isLoading} />
          <Tooltip placement="top" title={'Press to restart tests'}>
            <Button onClick={() => setRestartConfirm(true)} ><RedoOutlined /></Button>
          </Tooltip>
          <Modal
              title="Restart test process!"
              visible={restartConfirm}
              onOk={() => restartTests()}
              onCancel={() => setRestartConfirm(false)}
          >
            Are your sure your want to restart test process ?
          </Modal>
        </div>
      }>
        <Stats data={data.dataStats} />
      </Card>
      <Card title={<div className="table-header">
        <Title text="Latest" isLoading={data.isLoading} />
        { !editLatest ?
            <Tooltip placement="top" title={'Press to remove tests'}>
              <Button onClick={() => editMode('test')} ><DeleteOutlined /></Button>
            </Tooltip>:
            <div>
              <Button
                  style={{marginRight:5}}
                  onClick={() => cancelDel('test')} >Cancel</Button>
              <Button onClick={() => setConfirmL(true)} disabled={dropLastList.length === 0}>delete</Button>
            </div> }
      </div>}>
        <Modal
            title="Delete error test list!"
            visible={confirmDLast}
            onOk={() => dropTestLastMulti()}
            onCancel={() => setConfirmL(false)}
        >
          Are your sure your want to delete selected x of tests ?
        </Modal>
        <Accordion data={data.data} edit={editLatest} type={'test'}/>
      </Card>
      <Card title={<div className="table-header">
          <Title text="Last Error" isLoading={data.isLoading} />
        { !editErrorList ?
            <Tooltip placement="top" title={'Press to remove tests'}>
              <Button onClick={() => editMode('error')} ><DeleteOutlined /></Button>
            </Tooltip> :
            <div>
              <Button style={{
                marginRight:5,
              }} onClick={() => cancelDel('error')}>Cancel</Button>
              <Button onClick={() => setConfirmE(true)} disabled={dropTestErrorList.length === 0}>delete</Button>
            </div> }
        </div>}>
         <Modal
            title="Delete error test list!"
            visible={confirmDError}
            onOk={() => dropTestErrorMulti()}
            onCancel={() => setConfirmE(false)}
         >
           Are your sure your want to delete selected x of failed tests ?
         </Modal>
        <Accordion data={data.dataFail} edit={editErrorList} type={'error'}/>
      </Card>
    </>
  );
};

export default Results;


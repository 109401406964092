import React from "react";
import { Button, Modal, Form, Input, Alert } from "antd";


import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { Auth, Creds } from "../interfaces/interfaces";
import {login} from "../http";

type AuthProps = {
  auth: Auth;
  setAuth: (value: Auth) => void;
};

const AuthModal: React.FC<AuthProps> = ({ auth, setAuth }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const { success } = auth;

  React.useEffect(() => {
    setVisible(!success);
  }, [success]);

  const onFinish = async (values: Creds) => {
    setConfirmLoading(true);
    setError(null);

    try {
      await login(values.username, values.password)

      setAuth({
        creds: values,
        success: true,
      });
    } catch (e: any) {
      setError(e.toString());
    } finally {
      setConfirmLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title="Login"
      visible={visible}
      confirmLoading={confirmLoading}
      closable={false}
      footer={[]}
    >
      {error && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={confirmLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AuthModal;

import React, {useEffect, useState} from "react";
import {Popover, Progress, Statistic, Tooltip} from "antd";
import {useNavigate} from "react-router-dom";

interface CardContentProps {
    categoryItem: string
    subCat: string
    stats: any
}

const content = (data: any, subCat: string) =>  {
    if(!data) return null
    const dataSub = data[subCat];
    if(!dataSub) return null;
    const anHour = dataSub[0].total === 0 && dataSub[0].failed === 0 ? 0 : Math.floor((dataSub[0].total- dataSub[0].failed) * 100/ dataSub[0].total)
    const anDay = dataSub[1].total === 0 && dataSub[1].failed === 0 ? 0 : Math.floor((dataSub[1].total - dataSub[1].failed) * 100/ dataSub[1].total)
    const anSevenDay = dataSub[2].total === 0 && dataSub[2].failed === 0 ? 0 : Math.floor((dataSub[2].total - dataSub[2].failed) * 100/ dataSub[2].total)


    return <div>
        <div>
            <span> Fails during an hour</span>
            <Progress percent={anHour} />
        </div>
        <div>
            <span>Fails during a day</span>
            <Progress percent={anDay} />
        </div>
        <div>
            <span>Fails during 7 days</span>
            <Progress percent={anSevenDay}/>
        </div>
    </div>
};

const DashboardCardContent: React.FC<CardContentProps> = ({ categoryItem, subCat, stats}) => {
    const navigate = useNavigate();
    const [percent, setPercent] = useState(0)

    useEffect(() => {
        let total = 100;
        let failed =100;
        let percent1 = 0
        if(stats[categoryItem]) {
            // @ts-ignore
            total = stats[categoryItem][subCat][3].total;
            // @ts-ignore
            failed = stats[categoryItem][subCat][3].failed;
        }
        if(total === 0 && failed === 0){
            percent1 = 100
        }else{
            percent1 = Math.floor((total -  failed) * 100 / total)
        }
        if(percent1){
            setPercent(percent1)
        }
    }, [stats])

    if(!stats) return null

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: "solid",
        borderColor: '#f0f2f5',
        borderRadius: 10,
        borderWidth: 1,
        margin: 5,
        padding: 10
    }}>
        <Tooltip placement={"top"} title={"Press to see detail"}>
            <p
                onClick={() => {
                    navigate(`/category/${categoryItem}/test/${subCat}`)
                }
                }
                style={{ cursor: 'pointer', textAlign: 'center'}}>
                {subCat.replaceAll('_', ' ')}
            </p>
        </Tooltip>
        <Popover content={
            content(stats[categoryItem], subCat)
        } title="By periods" placement={"right"}>
            <Progress type={"circle"}
                      showInfo={true}
                      width={100}
                      format={(percent, successPercent) => {
                          return <>
                                                        <span style={{
                                                            color: '#ea2d2d',
                                                            fontSize: 18,
                                                        }}> { percent ? 100 - percent: 0 }%  </span>
                              /
                              <span style={{
                                  color: '#70c73d',
                                  fontSize: 18,
                              }}> {percent}% </span>
                          </>
                      }
                      }
                      percent={
                          percent
                      }
                      className={"progress-total"} >
                test
            </Progress>
        </Popover>
        {
            stats[categoryItem] &&  <Statistic
                title={`During month`}
                value={
                    //@ts-ignore
                    stats[categoryItem][subCat][3].failed
                }
                valueRender={(value) => {
                    return <span style={{color: '#af3541'}}>{value}</span>
                }
                }
                style={{textAlign: "center" }}
                suffix={<>/<span style={{color: '#127d2c'}}>{
                    // @ts-ignore
                    stats[categoryItem][subCat][3].total -  stats[categoryItem][subCat][3].failed
                }</span></>}
            />
        }
    </div>
}


export default DashboardCardContent

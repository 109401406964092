import { createStore, applyMiddleware, combineReducers, Store, Action } from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension';

import webSdk from './webSdkTest/reducer'
import main from './main/reducer'

const middlewares = [thunk]

export interface AppStore {
    main: MainState,
    webSdk: TestsState,
}


const rootReducer = combineReducers<AppStore>({
    main,
    webSdk
})

const middlewareEnhancer = applyMiddleware(...middlewares)
const composedEnhancers = composeWithDevTools(middlewareEnhancer)

export const store: Store<AppStore, Action> & {
    dispatch: DispatchType
} = createStore(rootReducer, composedEnhancers)


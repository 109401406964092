import React, {Dispatch, useEffect, useState, useCallback} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";
import { SelectInfo } from "rc-menu/lib/interface";
import {
  Auth,
  TestMapping,
} from "../interfaces/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {setCategoriesList, setSubCategoriesList} from "../store/main/actionCreators";
import {AppStore} from "../store/store";
import {getCategories, getSubCategories} from "../http";
import {clearDelErrorList, clearLastDList} from "../store/webSdkTest/actionCreators";
const { SubMenu } = Menu;

export interface AllProps {
  auth: Auth;
}

const MainMenu: React.FC<AllProps> = ({
  auth,
}): React.ReactElement => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch: Dispatch<any> = useDispatch();
  const { main } = useSelector<AppStore>((state) => state) as AppStore
  const subCategories: TestMapping = useSelector<AppStore>((state) => state.main.subCategories ) as TestMapping
  const { categories = []} = main;

  const loadCategories = useCallback(async () => {
    const data = await getCategories(auth.creds)

    // TODO: checks
   // setCategories(data);
    setOpenKeys(data);
    dispatch(setCategoriesList(data))
  }, [auth.creds]);

  const loadTestNames = useCallback(async () => {
    const data_map: TestMapping = await getSubCategories(categories, auth.creds)

    // TODO: checks
    //setTestMaping(data_map);
    dispatch(setSubCategoriesList(data_map))
    // todo dispatch sub categories to store instead
  }, [categories, auth.creds]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  useEffect(() => {
    loadTestNames();
  }, [loadTestNames]);

  useEffect(() => {
    const result = location.pathname.match(
        /^\/category\/(?<category>\w+)\/test\/(?<test>\w+)$/
    );
    if (result) setSelected([result[1], result[2]]);
    else setSelected([]);
  }, [location]);

  const openChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const select = (selected: SelectInfo) => {
    const [test, category] = selected.keyPath;
    navigate(`/category/${category}/test/${test}`);
  };



  return (
    <Menu
      onOpenChange={openChange}
      defaultOpenKeys={["WebSDK"]}
      openKeys={openKeys}
      mode="inline"
      selectedKeys={selected}
      onSelect={select}
      style={{ boxShadow: "1px 1px 3px 3px rgba(0, 191, 255, 0.1)" }}
    >
      {categories.map((category) => (
        <SubMenu key={category} icon={<VideoCameraOutlined />} title={category}>
          {subCategories[category] &&
            subCategories[category].map((testName) => (
              <Menu.Item
                  onClick={() => {
                    dispatch(clearLastDList())
                    dispatch(clearDelErrorList())
                  }
                  }

                  key={testName} style={{textTransform: 'capitalize'}}>{testName?.replaceAll('_', ' ')}</Menu.Item>
            ))}
        </SubMenu>
      ))}
    </Menu>
  );
};

export default MainMenu;

import * as actionsType from '../main/actionTypes';


// todo 1 load categories and sub categories for menu
//  2 save selected sub-cat
//  3 save auth data


const initialState: MainState = {
    categories: [],
    subCategories: [],
    stats: {}
}

const reducer = (state: MainState  = initialState, action: Action): MainState => {
    switch (action.type) {
        case actionsType.SET_MENU_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories,
            }
        case actionsType.SET_SUB_CATEGORIES:
            return {
                ...state,
                subCategories: action.payload.subCategories
            }
        case actionsType.SET_STATS_DATA: {
            return {
                ...state,
                stats: action.payload
            }
        }
    }
    return state
}
export default reducer;

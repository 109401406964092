import React from "react";
import {Row, Col, Statistic, Progress} from "antd";

import { StatData } from "../interfaces/interfaces";
import { chunks } from "../helpers/helpers";
import moment from "moment";

interface StatsProps {
  data: StatData[];
}

const Stats = React.memo<StatsProps>(({ data }) => {
  return (
    <>
      {data &&
        chunks(data, 4).map((row, i) => (
          <Row key={i} gutter={16}>
            {row.map((item, j) => {
              const duration = moment.duration(item.period, "s");
              const successPercent =  Math.round((item.total - item.failed) * 100 / item.total)
              let color = '#70a13d';
              if(successPercent < 50)
                color = '#e5d253'
              if(successPercent < 20)
                color = '#e23720'

              return (
                <Col key={j} span={6} style={{
                  display: 'flex',
                  flexDirection:"column",
                  alignItems:'center',
                  justifyContent:'center'}}>
                  <Progress
                      type="circle"
                      strokeLinecap={"round"}
                      strokeColor={color}
                      percent={successPercent}
                      showInfo={true}
                  />
                  <Statistic
                    title={`Fails during ${duration.humanize()}`}
                    value={item.failed}
                    style={{textAlign: "center" }}
                    suffix={`/ ${item.total}`}
                  />
                </Col>
              );
            })}
          </Row>
        ))}
    </>
  );
});

export default Stats;

import * as actionTypes from "./actionTypes"
import {multiRemoveErrors, multiRemoveTests} from "../../http";
import {message} from "antd";

export function addError(test: ITest) {
    console.log(test)
    const action: Action = {
        type: actionTypes.ADD_TO_ERROR_D_LIST,
        payload: test,
    }

    return action
}

export function addLast(test: ITest) {
    const action: Action = {
        type: actionTypes.ADD_TO_LAST_D_LIST,
        payload: test,
    }

    return action
}
export function removeLast(test: ITest) {
    const action: Action = {
        type: actionTypes.REMOVE_FROM_LAST_D_LIST,
        payload:test,
    }
    return action
}

export function removeError(test: ITest) {
    const action: Action = {
        type: actionTypes.REMOVE_FROM_ERROR_D_LIST,
        payload:test,
    }
    return action
}

export function clearDelErrorList() {
    return {
        type: actionTypes.CLEAR_DROP_LIST
    };
}

export function clearLastDList() {
    return {
        type: actionTypes.CLEAR_DROP_LAST_LIST
    };
}

export const dropMultiError = (auth:any) => async (dispatch: DispatchType, getState: any) => {
        const state = getState()
        try{
            await multiRemoveErrors(state.webSdk.dropTestErrorList, auth)
            dispatch({
                type: actionTypes.CLEAR_DROP_LIST,
                payload: {}
            })
        }catch (e){
            console.error("The attempt of deleting test is fallen!", e)
            message.error('The attempt of deleting test is fallen!');
        }
}

export const dropMultiLast= (auth:any) => async (dispatch: DispatchType, getState: any) => {
    const state = getState()
    try{
        await multiRemoveTests(state.webSdk.dropLastList, auth )
        dispatch({
            type: actionTypes.CLEAR_DROP_LAST_LIST,
            payload: {}
        })
    }catch (e){
        console.error("The attempt of deleting test is fallen!", e)
        message.error('The attempt of deleting test is fallen!');
    }
}

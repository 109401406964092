import React from "react";
import { Button, Layout, Typography } from "antd";
import { HomeTwoTone } from "@ant-design/icons";

import { Auth } from "./interfaces/interfaces";
import useLocalStorage from "./hooks/useLocalStorage";
import AuthModal from "./components/AuthModal";
import MainMenu from "./components/MainMenu";
import ContentRouter from "./components/ContentRouter";

import "./App.css";
import {useNavigate} from "react-router-dom";

const { Header, Footer, Sider, Content } = Layout;
const { Title } = Typography;

const defaultAuth: Auth = {
  creds: {
    username: "",
    password: "",
  },
  success: false,
};

function App() {
  const [auth, setAuth] = useLocalStorage<Auth>("auth", defaultAuth);
  const navigate = useNavigate();

  const logout = () => {
    setAuth(defaultAuth);
  };

  return (
    <>
      <AuthModal auth={auth} setAuth={setAuth} />
      {auth.success && (
        <Layout>

          <Sider width={256}>
            <div className='sceenic-logo' onClick={() => {
              navigate('/')
            } }/>
            <MainMenu
              auth={auth}
            />
          </Sider>
          <Layout>
            <Header>
              <Title
                level={2}
                style={{ textAlign: "center", marginTop: "0.25em" }}
              >
                <HomeTwoTone
                  style={{ marginRight: 10 }}
                  twoToneColor="#eb2f96"
                />
                E2E Platform
              </Title>
            </Header>
            <Content style={{ margin: "0 3em 2em 3em" }}>
              <ContentRouter auth={auth} />
            </Content>
            <Footer
              style={{
                textAlign: "right",
                display: "flex",
                borderTop: "thin solid #3333",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button type="dashed" onClick={logout}>
                Logout
              </Button>
              <span style={{ marginLeft: "2em" }}>2021 Sceenic</span>
            </Footer>
          </Layout>
        </Layout>
      )}
    </>
  );
}

export default App;

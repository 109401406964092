import React from "react";
import {TestData} from "../../../interfaces/interfaces";
import Paragraph from "antd/es/typography/Paragraph";
import {Table, message, Tooltip} from "antd";
import { CopyOutlined} from "@ant-design/icons";
import FailedResultCard from "./FailedResultCard";


interface AccordionDetailProps {
    test: TestData
}


const AccordionDetail: React.FC<AccordionDetailProps> = (item) => {

    const { Column } = Table;

    const data = [{
        key: '1',
        name: item.test.name,
        category: item.test.category,
        started: item.test.started,
        unique: item.test.unique,
        status: item.test.status,
        token: item.test.sessionToken,
        tags: ['test']
    }];

    return <div>
        <Table dataSource={data} pagination={false}>
            <Column
                title="ID"
                dataIndex="unique"
                key="unique"
                render={unique => {
                  return <Tooltip
                      placement={"top"} title={"Press to copy full"}>
                      <Paragraph
                          onClick={ () => {
                              navigator.clipboard.writeText(unique)
                              message.info('Unique Id copied!');
                          }
                          }
                  style={{
                      cursor:'pointer',
                      marginBottom: 0,
                  }}
                  >...{unique.slice(unique.length-16, unique.length-1)}</Paragraph></Tooltip>
                }}
            />
            <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(status) => {
                    if(status === -1) return <Paragraph style={{color: '#d73333', marginBottom: 0}}>Failed</Paragraph>
                    if(status === 0) return <Paragraph style={{color: '#1e75db', marginBottom: 0}} >Processed</Paragraph>
                    if(status === 1) return <Paragraph style={{color: '#4a8023', marginBottom: 0}}>Passed</Paragraph>
                }}
            />
                <Column
                    title="Name"
                    dataIndex="name"
                    key="name"
                />
            <Column
                title="Started"
                dataIndex="started"
                key="started"
            />
            {item.test.sessionToken && <Column
                title="Session token"
                dataIndex="token"
                key="token"
                render={token => {
                    return <>
                        <Paragraph style={{
                            marginBottom: 0,
                        }}>{token.slice(0,10)}...
                            <Tooltip placement="top" title={"Press to copy"}>
                        <CopyOutlined
                            onClick={() => {
                                navigator.clipboard.writeText(token)
                                message.info('Token copied to clipboard!');
                            }
                            }
                            style={{
                            paddingLeft: 5,
                            paddingRight:5,
                            cursor: 'pointer',
                            marginLeft:5}} />
                            </Tooltip>
                    </Paragraph>
                    </>
                }}
            />}
        </Table>
        <div style={{display: 'flex', flexDirection: 'column'}}>
           <FailedResultCard test={item.test} />
        </div>

    </div>

}

export default AccordionDetail;

import * as testActions from './actionTypes'
const initialState = {
    dropTestErrorList: [],
    dropLastList: [],
    latestTestList: [],
    lastErrorList: [],
}

const reducer = (
    state: TestsState = initialState,
    action: Action
): TestsState => {
    switch (action.type) {
        case testActions.ADD_TO_ERROR_D_LIST:
            return {
                ...state,
                dropTestErrorList: [...state.dropTestErrorList, action.payload],
            }
        case testActions.REMOVE_FROM_ERROR_D_LIST:
            const updateErrList: ITest[] = state.dropTestErrorList.filter(
                test => test.unique !== action.payload.unique
            )
            return {
                ...state,
                dropTestErrorList: updateErrList,
            }
        case testActions.ADD_TO_LAST_D_LIST:
            return {
                ...state,
                dropLastList: [...state.dropLastList, action.payload]
            }
        case testActions.REMOVE_FROM_LAST_D_LIST:
            const updateLastList: ITest[] = state.dropLastList.filter(
                test => test.unique !== action.payload.unique
            )
            return {
                ...state,
                dropLastList: updateLastList,
            }
        case testActions.CLEAR_DROP_LIST:
            return {
                ...state,
                dropTestErrorList: []
            }
        case testActions.CLEAR_DROP_LAST_LIST: {
            return {
                ...state,
                dropLastList: []
            }
        }
    }
    return state
}

export default reducer
